<template>
  <model title="MessageBox" tag="消息弹窗">
    <h3>示例用法</h3>
    <lh-button @click="openMessageBox">打开消息弹窗</lh-button>
		<div>
			<lh-collapse>
				<div slot="title">示例代码</div>
				<lh-code-editor v-model="code1" :readonly="true"/>
			</lh-collapse>
		</div>
		<h3>函数方法参考表</h3>
		<lh-table>
      <lh-tr>
        <lh-th>方法名</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>参数</lh-th>
        <lh-th>返回值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>lhMessageBox</lh-td>
        <lh-td>弹出消息框</lh-td>
        <lh-td>第一个参数是弹框标题，第二个参数是弹框文本内容。</lh-td>
        <lh-td>Promise回调</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
	name:"MessageBoxView",
  components: { Model },
	data(){
		return{
			code1:
				"<template>\n"+
				"  <lh-button @click=\"openMessageBox\">打开消息弹窗</lh-button>\n"+
				"</template>\n\n"+
				"<script>\n"+
				"export default {\n"+
				"	methods:{\n"+
				"		openMessageBox:function(){\n"+
				"			this.$lhMessageBox(\"弹窗标题\",\"消息内容\").then(()=>{\n"+
				"				console.log(\"确定\");\n"+
				"			}).catch(()=>{\n"+
				"				console.log(\"关闭\");\n"+
				"			});\n"+
				"		}\n"+
				"	}\n"+
				"}\n"+
				"</scr"+"ipt>"
		}
	},
	methods:{
		openMessageBox:function(){
			this.$lhMessageBox("弹窗标题","消息内容").then(()=>{
				console.log("确定");
			}).catch(()=>{
				console.log("关闭");
			});
		}
	}
}
</script>

<style>

</style>